import React, { useEffect } from "react";
import Section from "../components/Section/Section";
import Template from "../components/Template/Template";
import Hero from "../components/Hero/Hero";
import { graphql, StaticQuery } from "gatsby";
import { Col } from "react-bootstrap";
import SafeLink from "../components/SafeLink/SafeLink";
import { Details } from "@material-ui/icons";
import { trackEvent } from "../utils/tealium";

export const Error404 = (props) => {
  useEffect(() => {
    trackEvent({
      eventCategory: "404",
      eventAction: "Pageload",
      eventLabel: document.referrer,
    });
  }, []);

  return (
    <Template>
      <Section className="bg-bsm-grey-6 top-fill">
        <Hero
          page="404"
          smallText={"We can’t find the page you’re looking for :-o"}
          largeText={"Oops!"}
        />
      </Section>
      <Section className="bg-bsm-primary-white " btrr>
        <Col md={4} className="mt-5">
          <p className="mt-5 mb-3">Here are some helpful links instead</p>
          <StaticQuery
            query={graphql`
              query {
                file(name: { eq: "error" }) {
                  childMenuJson {
                    menus {
                      edges {
                        node {
                          id
                          menuItems {
                            edges {
                              node {
                                childItems {
                                  edges {
                                    node {
                                      id
                                      label
                                      url
                                    }
                                  }
                                }
                                id
                                label
                                url
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  name
                }
              }
            `}
            render={data => (
              <ul>
                {data.file.childMenuJson.menus.edges.length
                  ? data.file.childMenuJson.menus.edges[0].node.menuItems.edges.map(
                      ({ node }, i) => (
                        <li key={i} className="mb-3">
                          <SafeLink to={node.url}>
                            <span
                              dangerouslySetInnerHTML={{ __html: node.label }}
                            />
                          </SafeLink>
                        </li>
                      )
                    )
                  : null}
              </ul>
            )}
          />
        </Col>
      </Section>
    </Template>
  );
};

export default Error404;